import * as api from '../../api';

const handleLogin = ({
  cognitoUser,
  setErrorMessage,
  setIsLoggingIn,
  setMfaModalOpen,
}) => {
  const {
    idToken,
    refreshToken,
    accessToken,
  } = cognitoUser.signInUserSession;

  const url = new URL(window.location.href);
  const searchParams = new URLSearchParams(url.search);
  const returnPath = searchParams.get('returnPath');
  const defaultRedirect = new URL(process.env.REACT_APP_COMPASS_PLUS_URL);

  let redirectToAfterLogin = defaultRedirect;

  if (null !== returnPath) {
    try {
      redirectToAfterLogin = new URL(returnPath, process.env.REACT_APP_COMPASS_PLUS_URL);

      // Avoid redirects that aren't in the default domain
      if (redirectToAfterLogin.host !== defaultRedirect.host) {
        redirectToAfterLogin = defaultRedirect;
      }
    } catch {
      // Do nothing - redirect will stay as default
    }
  }

  const handleCatch = (error) => {
    setErrorMessage(error.response?.data?.error ?? error.response?.data?.detail ?? null);
    setIsLoggingIn(false);
    if (setMfaModalOpen) {
      setMfaModalOpen(false);
    }
  };

  api.loginCompassPlus(idToken.jwtToken, refreshToken.token, accessToken.jwtToken)
    .then(() => {
      Promise.all([
        api.loginCareersPartners(idToken.jwtToken, refreshToken.token, true),
        api.loginCompassClassic(idToken.jwtToken, refreshToken.token),
      ])
        .then(() => window.location = redirectToAfterLogin.toString())
        .catch(handleCatch)
      ;
    })
    .catch(handleCatch)
  ;
};

export default handleLogin;
